import AboutForm from "components/StartupForm/AboutForm";
import ChallengeForm from "components/StartupForm/ChallengeForm";
import ConcernForm from "components/StartupForm/ConcernForm";
import DetailsForm from "components/StartupForm/DetailsForm";
import FirstStep from "components/StartupForm/FirstStep";
import GoalForm from "components/StartupForm/GoalForm";
import buildIcon from "components/StartupForm/GoalForm/assets/build.svg";
import establishIcon from "components/StartupForm/GoalForm/assets/establish.svg";
import refineIcon from "components/StartupForm/GoalForm/assets/refine.svg";
import validateIcon from "components/StartupForm/GoalForm/assets/validate.svg";
import IntroForm from "components/StartupForm/IntroForm";
import PlansForm from "components/StartupForm/PlansForm";
import ServiceForm from "components/StartupForm/ServiceForm";
import competitorIcon from "components/StartupForm/ServiceForm/assets/competitor.svg";
import marketIcon from "components/StartupForm/ServiceForm/assets/market.svg";
import researchIcon from "components/StartupForm/ServiceForm/assets/research.svg";
import StateForm from "components/StartupForm/StateForm";
import marketIcon2 from "components/StartupForm/StateForm/assets/market.svg";
import marketIcon3 from "components/StartupForm/StateForm/assets/market2.svg";
import productIcon from "components/StartupForm/StateForm/assets/product.svg";
import startedIcon from "components/StartupForm/StateForm/assets/started.svg";
import valueIcon from "components/StartupForm/StateForm/assets/value.svg";

export const FormComponents = [
  {
    title: "First Step",
    component: <FirstStep field={""} />,
    field: "",
    showNavigation: false,
  },
  {
    title: "Intro Form",
    component: <IntroForm field={""} />,
    field: "",
    showNavigation: false,
  },
  {
    title: "Current State",
    component: <StateForm field={"currentState"} />,
    field: "currentState",
    showNavigation: true,
  },
  {
    title: "Company Goal",
    component: <GoalForm field={"goal"} />,
    field: "goal",
    showNavigation: true,
  },
  {
    title: "Services",
    component: <ServiceForm field={"service"} />,
    field: "service",
    showNavigation: true,
  },
  {
    title: "About yourself",
    component: <AboutForm field={"about"} />,
    field: "about",
    showNavigation: true,
  },
  {
    title: "Challenges",
    component: <ChallengeForm field={"challenges"} />,
    field: "challenges",
    showNavigation: true,
  },
  {
    title: "Concerns",
    component: <ConcernForm field={"concern"} />,
    field: "concern",
    showNavigation: true,
  },
  {
    title: "Details",
    component: <DetailsForm field={"userDetails"} />,
    field: "userDetails",
    showNavigation: true,
  },
  {
    title: "Plans",
    component: <PlansForm field={""} />,
    field: "",
    showNavigation: true,
  },
];

export const companyServices = [
  {
    title: "Market Research",
    subtitle:
      "In-depth analysis of the target market, including market size, trends, and potential opportunities. Identification of key competitors and their strategies.",
    icon: marketIcon,
  },
  {
    title: "Competitor Analysis",
    subtitle:
      "Detailed analysis of competitors' strengths, weaknesses, and market positioning. Evaluation of competitor products or services and their unique selling points.",
    icon: competitorIcon,
  },
  {
    title: "Market Research",
    subtitle:
      "Custom-designed survey to gather feedback and insights from the target audience. Analysis of survey results to understand customer needs, preferences, and pain points.",
    icon: researchIcon,
  },
];

export const companyGoals = [
  {
    title: "Validate our concepts and assess their market potential",
    icon: validateIcon,
  },
  {
    title: "Refine our idea and make informed decisions",
    icon: refineIcon,
  },
  {
    title:
      "Establish a strong brand foundation and create a compelling brand story",
    icon: establishIcon,
  },
  {
    title:
      "Build a memorable and authentic brand that resonates with our target audience.",
    icon: buildIcon,
  },
];

export const currentStateOptions = [
  {
    title: "Work on Your Branding",
    subtitle:
      "We are in the early stages of conceptualizing our business idea and exploring potential solutions.",
    icon: startedIcon,
    image: "/assets/logos/bg1.svg",
  },
  {
    title: "Create Your Product",
    subtitle:
      "We are working on the development of our product, implementing our product roadmap.",
    icon: productIcon,
    image: "/assets/logos/bg2.svg",
  },
  {
    title: "Market Your Business",
    subtitle:
      "Having identified a specific market problem, we have developed a solution and are iterating based on user feedback.",
    icon: valueIcon,
    image: "/assets/logos/bg3.svg",
  },
];

export const challenges = [
  "Balancing technical expertise with business and marketing skills",
  "Ensuring that the product meets market needs and user expectations",
  "Adapting to changing market trends and demands",
  "Managing and retaining a talented team",
  "Balancing social impact with financial sustainability",
  "Lack of market knowledge and insights",
  "Identifying and understanding potential",
  "Communicating the unique value proposition effectively",
  "Building a strong network of mentors and industry experts",
  "Identifying the target audience and understanding their needs",
  "Limited resources and budget",
  "Competing with established players in the market",
  "Attracting investors and securing funding for the startup",
];
export const concerns = [
  "Concerns about the cost-effectiveness of the services offered",
  "Skepticism around the financial viability of social impact startups",
  "Doubts regarding the ability to deliver accurate market research and insights",
  "Balancing social impact with profit generation",
  "Reliability and expertise of the service provider",
  "Concerned about protecting sensitive information and user data",
  "Skepticism about the potential value and impact of the services provided",
  "Ensuring credibility and transparency in impact measurement and reporting",
  "Alignment with the startup's specific needs and goals",
  "Concerned about integrating new technologies with existing systems or infrastructure",
  "Concerns about the compatibility and fit with the startup's vision and goals",
  "Attracting and retaining talent committed to the social impact mission",
  "Potential risks and challenges associated with the service",
];

export const aboutQuestions = [
  {
    question: "What domains would you say you have experience in?",
    option: [
      "Branding",
      "Product Design and Management",
      "Development",
      "Sales",
      "Marketing",
      "None of these",
    ],
  },
  {
    question: "Have you had prior experience building business?",
    option: ["Yes", "No", "Maybe"],
  },
  {
    question: "Would you consider yourself socially conscious",
    option: ["Yes", "No", "Maybe"],
  },
  {
    question: "Do you envision your startup as a social impact enterprise",
    option: ["Yes", "No", "Not a primary focus, but open to it"],
  },
  {
    question: "Would you consider yourself to be tech-savvy",
    option: ["Yes", "No", "Maybe"],
  },
  {
    question: "Do you envision your startup being driven by technology?",
    option: ["Yes", "No", "Not a primary focus, but open to it"],
  },
];
