import { aboutQuestions } from "helpers/constants/startupForm";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDetails } from "redux/startupForm";

const AboutForm = ({ field }) => {
  const { details } = useSelector((state) => state.startupForm);
  const dispatch = useDispatch();

  const handleOptionSelect = (question, option) => {
    let about = details[field];
    about = { ...about, [question]: option };

    dispatch(updateDetails({ field, value: about }));
  };

  return (
    <div className="md:px-20 w-full">
      <div className=" p-4 md:p-6 md:px-10 flex flex-col gap-6 md:gap-8 ">
        <div className="flex flex-col gap-2 text-center">
          <h1 className="text-lg md:text-xl font-poppins tracking-[2%]">Just A Few More Steps</h1>
          <p className="font-inter text-sm md:text-base tracking-[2%] font-light">
            Tell us a little bit about yourself
          </p>
        </div>
        <div className="py-4 flex flex-col gap-14 pb-20 ">
          {aboutQuestions.map((_question, idx) => {
            const { question, option } = _question;
            return (
              <div key={idx} className="flex flex-col gap-6">
                <h3 className="font-inter font-semibold text-base tracking-[2%] leading-7 text-primary-grey-800 ">
                  {question}
                </h3>
                <div className="flex flex-wrap items-center gap-4 md:gap-6">
                  {option.map((option, idx) => {
                    const isSelected = details[field][question] === option;
                    return (
                      <div
                        key={idx}
                        onClick={() => handleOptionSelect(question, option)}
                        className={`rounded-full p-5 py-[10px] border border-primary-grey-200 cursor-pointer hover:bg-primary-grey-150 ${
                          isSelected && "bg-primary-grey-150"
                        } `}>
                        <p className="font-inter text-sm tracking-[2%] text-primary-grey-800">{option}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AboutForm;
