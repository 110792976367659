import { currentStateOptions } from "helpers/constants/startupForm";
import { useDispatch, useSelector } from "react-redux";
import { updateDetails } from "redux/startupForm";
import {
  MdOutlineRadioButtonChecked,
  MdOutlineRadioButtonUnchecked,
} from "react-icons/md";

const StateForm = ({ field }) => {
  const { details } = useSelector((state) => state.startupForm);
  const dispatch = useDispatch();

  const handleOptionClick = (option) => {
    let value;
    if (details[field] === option) {
      value = "";
    } else {
      value = option;
    }
    dispatch(updateDetails({ field, value }));
  };

  return (
    <div className="p-4 md:p-6 md:px-10 flex flex-col gap-8 ">
      <div className="flex flex-col gap-2 text-center">
        <h1 className="text-lg md:text-xl font-poppins tracking-[2%]">
          Which of these best describe the current state of your startup?
        </h1>
        <p className="font-inter text-sm md:text-base tracking-[2%] font-light">
          We’ll help you based on your needs
        </p>
      </div>
      <div className="w-full max-w-[960px] mx-auto flex flex-col py-4 pb-20 ">
        {currentStateOptions.map((option, idx) => {
          const { title, subtitle, icon } = option;
          const isSelected = details[field] === title;
          return (
            <div
              key={idx}
              onClick={() => handleOptionClick(title)}
              className={`p-2 py-6 md:p-6 md:px-10 flex gap-3 md:gap-6 items-center border-b border-opacity-30 cursor-pointer ${
                isSelected
                  ? "bg-gradient-to-r from-[#FDF7E980] to-[#FFE9E580]"
                  : "bg-white"
              } `}
            >
              {!isSelected ? (
                <MdOutlineRadioButtonUnchecked className="text-primary-grey-300 text-xl" />
              ) : (
                <MdOutlineRadioButtonChecked className="text-primary-yellow-600 text-xl " />
              )}

              <div className="flex flex-col gap-2 font-inter font-semibold text-sm md:text-md tracking-[2%] leading-6 flex-1 ">
                <h3>{title}</h3>
                <p className="font-light ">{subtitle}</p>
              </div>
              <img src={icon} alt="" className="w-14 h-14 object-contain" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StateForm;
