import { FaArrowRight } from "react-icons/fa6";
import OTPInput from "./OTPInput";

const convertEmail = (email) => {
  const [username, domain] = email.split("@");
  const hiddenUsername = `${username.slice(0, 2)}${"*".repeat(
    Math.max(0, username.length - 2)
  )}`;
  return `${hiddenUsername}@${domain}`;
};

const Dropdown = ({
  startupNames,
  setSelectedStartup,
  setShowDropdown,
  dropdownRef,
  setInputValue,
}) => (
  <div
    className="absolute top-20 z-10 flex flex-col w-full rounded-lg overflow-hidden overflow-y-auto max-h-52"
    id="dropdown"
    ref={dropdownRef}
  >
    {startupNames.map((startup, idx) => (
      <div
        className="flex items-center gap-2 bg-white w-full  px-4 py-2 border-b border-gray-300 cursor-pointer hover:bg-opacity-95"
        key={startup?.name?.trim() + idx}
        onClick={() => {
          setSelectedStartup(startup);
          setShowDropdown(false);
          setInputValue(startup.name);
        }}
      >
        {/* {startup.logo ? (
          <img
            src={startup.logo}
            alt={startup.name}
            className="w-6 h-6 rounded-full"
          />
        ) : (
          <div className="w-6 h-6 rounded-full bg-gray-300"></div>
        )} */}
        <p className="text-black font-inter font-semibold text-sm text-left leading-5">
          {startup.name}
        </p>
      </div>
    ))}
  </div>
);

const StepOne = ({
  inputValue,
  handleInput,
  inputRef,
  startupNames,
  setSelectedStartup,
  setShowDropdown,
  showDropdown,
  dropdownRef,
  setInputValue,
  cb,
}) => (
  <>
    <div className="w-full text-center">
      <p className="text-white capitalize font-inter text-xs lg:text-sm font-light leading-4 lg:leading-7 mb-4">
        Enter your startup name to get started
      </p>
      <div className="relative flex flex-col h-[56px] w-full gap-1 rounded-lg bg-white border">
        <input
          type="text"
          name="startup"
          id="startup"
          className="w-full border-none h-full text-black text-sm py-3 px-6 pr-[60px] self-stretch rounded-lg placeholder:text-primary-neutral-300 ring-0"
          placeholder="Start Typing..."
          ref={inputRef}
          value={inputValue}
          onChange={handleInput}
          onFocus={() => setShowDropdown(true)}
          autoComplete="off"
        />
        <div
          className="absolute right-0 h-full w-[56px] bg-neutral-300 rounded-r-lg cursor-pointer"
          onClick={cb}
        >
          <FaArrowRight className="text-[#9C9A96] absolute top-1/2 transform -translate-y-1/2 translate-x-1/2 right-1/2 text-xl" />
        </div>
        {showDropdown && (
          <Dropdown
            {...{
              startupNames,
              setSelectedStartup,
              setShowDropdown,
              dropdownRef,
              setInputValue,
            }}
          />
        )}
      </div>
    </div>
  </>
);

const StepTwo = ({ selectedStartup, email, setEmail, setShowDropdown }) => (
  <>
    <div>
      <div className="flex gap-2 items-center justify-center mb-3">
        {selectedStartup?.logo ? (
          <img
            src={selectedStartup.logo}
            alt={selectedStartup.name}
            className="w-6 h-6 rounded-full"
          />
        ) : (
          <div className="w-6 h-6 rounded-full bg-gray-300"></div>
        )}
        <p className="text-white font-inter font-bold text-sm leading-7">
          {selectedStartup?.name}
        </p>
      </div>
      <p className="text-white capitalize font-inter text-xs lg:text-sm font-light leading-4 lg:leading-7 mb-4">
        Please enter the email you want to use
      </p>
    </div>
    <div className="w-5/6 text-center">
      <div className="relative flex flex-col w-full gap-1">
        <label
          htmlFor="email"
          className="text-white capitalize text-left font-inter text-xs lg:text-sm font-light leading-4 lg:leading-7"
        >
          Email Address
        </label>
        <input
          type="text"
          name="email"
          id="email"
          className="w-full rounded-lg bg-white border text-black text-sm py-3 px-6 self-stretch"
          placeholder="Start Typing..."
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onFocus={() => setShowDropdown(true)}
          autoComplete="off"
        />
      </div>
    </div>
  </>
);

const StepThree = ({ selectedStartup, email, otp, setOtp }) => (
  <>
    <div>
      <div className="flex gap-2 items-center justify-center mb-3">
        {selectedStartup?.logo ? (
          <img
            src={selectedStartup.logo}
            alt={selectedStartup.name}
            className="w-6 h-6 rounded-full"
          />
        ) : (
          <div className="w-6 h-6 rounded-full bg-gray-300"></div>
        )}
        <p className="text-white font-inter font-bold text-sm leading-7">
          {selectedStartup?.name}
        </p>
      </div>
      <p className="text-gray-300 font-inter text-sm font-light leading-7">
        Please enter the OTP sent to your email id
      </p>
      <p className="text-center text-white font-normal text-sm">
        {convertEmail(email)}
      </p>
    </div>
    <div className="w-5/6 text-center">
      <div className="relative flex flex-col w-full gap-1">
        <OTPInput otp={otp} setOtp={setOtp} />
      </div>
    </div>
  </>
);

export { StepOne, StepTwo, StepThree };
