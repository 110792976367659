import React from "react";
import { FiSearch } from "react-icons/fi";
import GradientButton from "components/Common/Button/GradientButton";
import { AiOutlineMail } from "react-icons/ai";

const PlansForm = () => {
  return (
    <div className="md:px-20 w-full  pb-20">
      <div className="p-4 md:p-6 md:px-10 flex flex-col gap-8">
        <div className="flex flex-col gap-2 text-center">
          <h1 className="text-lg md:text-xl font-poppins tracking-[2%]">
            Our Recommended solutions for you
          </h1>
          <p className="font-inter text-sm md:text-base tracking-[2%] font-light">
            Premium talent from top institutes across the country for you to
            choose from!
          </p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 p-6 shadow rounded-3xl border w-full bg-gradient-to-b from-[#E9FCE8] to-white ">
          <div className="flex flex-col gap-4">
            <FiSearch />
            <h2 className="poppins font-bold text-lg tracking-[2%]">
              Dream Forger Suite
            </h2>
            <p className="font-inter font-light text-sm leading-5">
              Designed to help startups in the idea stage validate their
              concepts and assess their market potential, through a combination
              of market research, competitor analysis, and customer insights.
            </p>
          </div>
          <div className="flex flex-col gap-4">
            <h2 className=" pl-12 font-poppins font-light text-md tracking-[2%] ">
              What You Get
            </h2>
            <div className="flex flex-col gap-6">
              <div className="flex gap-4 p-2">
                <FiSearch />
                <h2 className=" font-inter font-semibold text-md tracking-[2%] ">
                  Market Research Report
                </h2>
              </div>
              <hr />
              <div className="flex gap-4 p-2">
                <FiSearch />
                <h2 className=" font-inter font-semibold text-md tracking-[2%] ">
                  Market Research Report
                </h2>
              </div>
              <hr />
              <div className="flex gap-4 p-2">
                <FiSearch />
                <h2 className=" font-inter font-semibold text-md tracking-[2%] ">
                  Market Research Report
                </h2>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <h2 className=" pl-12 font-poppins font-light text-md tracking-[2%] ">
              Who You Would Need
            </h2>
            <div className="flex flex-col gap-6">
              <div className="flex gap-4 p-2 items-center">
                <h1 className="font-poppins font-light text-3xl tracking-[2%] text-primary-red-500">
                  12
                </h1>
                <div className="flex flex-col">
                  <h2 className=" font-inter font-semibold text-md tracking-[2%] ">
                    Business Strategists
                  </h2>
                  <p className="font-inter font-light text-xs tracking-[2%]">
                    to choose from
                  </p>
                </div>
              </div>
              <hr />
              <div className="flex gap-4 p-2 items-center">
                <h1 className="font-poppins font-light text-3xl tracking-[2%] text-primary-red-500">
                  12
                </h1>
                <div className="flex flex-col">
                  <h2 className=" font-inter font-semibold text-md tracking-[2%] ">
                    Business Strategists
                  </h2>
                  <p className="font-inter font-light text-xs tracking-[2%]">
                    to choose from
                  </p>
                </div>
              </div>
              <hr />
              <div className="flex gap-4 p-2 items-center">
                <h1 className="font-poppins font-light text-3xl tracking-[2%] text-primary-red-500">
                  12
                </h1>
                <div className="flex flex-col">
                  <h2 className=" font-inter font-semibold text-md tracking-[2%] ">
                    Business Strategists
                  </h2>
                  <p className="font-inter font-light text-xs tracking-[2%]">
                    to choose from
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 p-6 shadow rounded-3xl border w-full bg-gradient-to-b from-[#FFF1EB] to-white ">
          <div className="flex flex-col gap-4">
            <FiSearch />
            <h2 className="poppins font-bold text-lg tracking-[2%]">
              Dream Forger Suite
            </h2>
            <p className="font-inter font-light text-sm leading-5">
              Designed to help startups in the idea stage validate their
              concepts and assess their market potential, through a combination
              of market research, competitor analysis, and customer insights.
            </p>
          </div>
          <div className="flex flex-col gap-4">
            <h2 className=" pl-12 font-poppins font-light text-md tracking-[2%] ">
              What You Get
            </h2>
            <div className="flex flex-col gap-6">
              <div className="flex gap-4 p-2">
                <FiSearch />
                <h2 className=" font-inter font-semibold text-md tracking-[2%] ">
                  Market Research Report
                </h2>
              </div>
              <hr />
              <div className="flex gap-4 p-2">
                <FiSearch />
                <h2 className=" font-inter font-semibold text-md tracking-[2%] ">
                  Market Research Report
                </h2>
              </div>
              <hr />
              <div className="flex gap-4 p-2">
                <FiSearch />
                <h2 className=" font-inter font-semibold text-md tracking-[2%] ">
                  Market Research Report
                </h2>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <h2 className=" pl-12 font-poppins font-light text-md tracking-[2%] ">
              Who You Would Need
            </h2>
            <div className="flex flex-col gap-6">
              <div className="flex gap-4 p-2 items-center">
                <h1 className="font-poppins font-light text-3xl tracking-[2%] text-primary-red-500">
                  12
                </h1>
                <div className="flex flex-col">
                  <h2 className=" font-inter font-semibold text-md tracking-[2%] ">
                    Business Strategists
                  </h2>
                  <p className="font-inter font-light text-xs tracking-[2%]">
                    to choose from
                  </p>
                </div>
              </div>
              <hr />
              <div className="flex gap-4 p-2 items-center">
                <h1 className="font-poppins font-light text-3xl tracking-[2%] text-primary-red-500">
                  12
                </h1>
                <div className="flex flex-col">
                  <h2 className=" font-inter font-semibold text-md tracking-[2%] ">
                    Business Strategists
                  </h2>
                  <p className="font-inter font-light text-xs tracking-[2%]">
                    to choose from
                  </p>
                </div>
              </div>
              <hr />
              <div className="flex gap-4 p-2 items-center">
                <h1 className="font-poppins font-light text-3xl tracking-[2%] text-primary-red-500">
                  12
                </h1>
                <div className="flex flex-col">
                  <h2 className=" font-inter font-semibold text-md tracking-[2%] ">
                    Business Strategists
                  </h2>
                  <p className="font-inter font-light text-xs tracking-[2%]">
                    to choose from
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center p-4 gap-2">
          <h2 className="font-inter text-lg md:text-xl font-light tracking-[2%] text-center ">
            Get in touch with our solutions expert to get started
          </h2>
          <GradientButton
            text="rashi@caarya.in"
            icon={<AiOutlineMail />}
            align="left"
          />
        </div>
      </div>
    </div>
  );
};

export default PlansForm;
