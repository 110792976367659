import Navigation from "components/Common/Navigation";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { saveInfo } from "config/APIs/startups";
import { resetGetInTouchState } from "redux/getInTouch";
function Pricing() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { details, information } = useSelector((state) => state.getInTouch);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1120,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const saveData = async () => {
    try {
      const payload = {
        ...details,
        ...information,
      };
      console.log(information, details, payload);
      if (
        !payload?.name ||
        !payload?.ownerName ||
        !payload?.email ||
        !payload?.phoneNumber
      ) {
        return;
      }
      await saveInfo(payload);

      dispatch(resetGetInTouchState());
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    saveData();
  }, []);

  return (
    <section className="bg-white">
      <Navigation />
      <div className="relative min-h-screen w-full overflow-x-hidden py-10 space-y-10 lg:space-y-20 px-4 max-w-7xl mx-auto">
        <div className="w-full flex flex-col items-center py-4 mx-auto max-w-5xl">
          <h1 className="font-light text-center text-lg lg:text-[32px] lg:leading-10 bg-clip-text bg-gradient-to-b from-[#FF7E6E] to-[#FFBC00] text-transparent">
            Congratulations on getting started with your venture acceleration
            journey with Caarya today
          </h1>
          <p className="text-sm lg:text-xl font-light font-inter text-center text-gray-800 mt-5">
            Our startup solutions expert will reach out to you shortly. In the
            meantime do check out our services.
          </p>{" "}
        </div>{" "}
        {/* <div className="w-full flex flex-col items-center space-y-3 py-4">
          <h1 className="font-light text-center text-xl lg:text-4xl bg-clip-text bg-gradient-to-b from-[#FF7E6E] to-[#FFBC00] text-transparent pb-2">
            Congratulations {details?.startupName}
          </h1>
          <p className="text-sm lg:text-xl font-light font-inter text-center text-gray-800 mt-2">
            On getting started with your venture acceleration journey with
            Caarya today.
            <br />A startups solutions expert will reach out to you shortly. In
            the meantime, do check out our services.
          </p>
        </div> */}
        {/* <div className="w-full mx-auto max-w-7xl ">
          <Slider {...settings}>
            {[1, 2, 3, 4, 5]?.map((item) => {
              return (
                <div className="pb-4 px-2.5">
                  <div
                    style={{
                      boxShadow:
                        "1px 4px 10px -4px rgba(0, 0, 0, 0.02), 4px 11px 24px -3px rgba(0, 0, 0, 0.05), 0px -2px 20px 0px rgba(0, 0, 0, 0.05)",
                    }}
                    className="bg-white max-w-[200px] rounded-[18px] p-6 flex flex-col space-y-4"
                  >
                    <p className="text-caarya-red-lighter font-inter text-[48px] font-semibold">
                      12
                    </p>
                    <div className="flex flex-col">
                      <h1 className="text-black font-poppins text-sm font-medium leading-5">
                        Business Strategist
                      </h1>
                      <h2 className="text-primary-neutral-800 font-inter text-2xs font-light leading-4">
                        to chose from
                      </h2>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div> */}
        <div className="flex flex-col items-center max-w-7xl mx-auto space-y-10">
          {/* <p className="text-caarya-red-lighter font-poppins text-base lg:text-xl font-light leading-7 capitalize tracking-[1px]">
            Please select the services you would want to avail
          </p> */}
          <div className="grid lg:grid-cols-3 gap-6">
            {[
              {
                color: "#2BB656",
                borderColor: "#C2F2BD",
                plan: "Free",
                description: (
                  <>
                    This is for if you have <b>immediate hiring needs</b> and
                    are looking for a few profiles to shortlist from
                  </>
                ),
                price: "0",

                points: [
                  {
                    text: "Basic access to FTP",
                    subtext:
                      "Connect with startup-ready talent to simplify initial hiring.",
                  },
                  {
                    text: "Dedicated recruitment drive ",
                    subtext:
                      "Targeted talent outreach to efficiently build your team.",
                  },
                  {
                    text: "Brand Promotion through Startup Story",
                    subtext:
                      "Custom made story focusing on the brands vision and values",
                  },
                ],
              },
              {
                color: "#3996E3",
                borderColor: "#3996E3",
                plan: "Standard",
                description: (
                  <>
                    This is for you if you are looking for{" "}
                    <b>long term team building</b> and want to hire culture and
                    value fit candidates
                  </>
                ),
                price: "2500",
                subtext: "Everything in free plus",
                points: [
                  {
                    text: "Brand promotion collateral",
                    subtext:
                      "Create an attractive Employer Value Proposition to boost brand appeal to candidates.",
                  },
                  {
                    text: "Stargazer tool",
                    subtext:
                      "An advanced talent matching tool to ensure optimal team alignment.",
                  },
                  {
                    text: "Dedicated SSE",
                    subtext:
                      "Personalized growth guidance to accelerate startup development.",
                  },
                ],
              },
              {
                color: "#816FE9",
                borderColor: "#816FE9",
                plan: "Premium",
                description: (
                  <>
                    This is for you if you want to focus on{" "}
                    <b>venture growth</b> and hyper agile deliveries through
                    transactional teams
                  </>
                ),
                price: "5000",
                subtext: "Everything in free & standard plus",
                points: [
                  {
                    text: "Startup Nexus",
                    subtext:
                      "Connect with mentors and partners to unlock new growth opportunities.",
                  },
                  {
                    text: "POD Calendar",
                    subtext:
                      "Optimize and adapt team structure to stay agile and delivery-focused",
                  },
                ],
              },
            ]?.map((item) => {
              return (
                <div
                  style={{ borderColor: item?.borderColor }}
                  className="bg-white rounded-xl p-8 border flex flex-col space-y-5"
                >
                  <div className="flex flex-col space-y-2">
                    <h1
                      style={{ color: item?.color }}
                      className="font-poppins text-sm lg:text-base leading-6 font-semibold tracking-[0.64px] uppercase"
                    >
                      {item?.plan}
                    </h1>
                    <p className="text-primary-neutral-800 font-inter text-xs lg:text-sm font-light leading-5">
                      {item?.description}
                    </p>
                  </div>
                  {/* <p className="text-primary-neutral-800 font-inter text-[56px] font-semibold">
                    ₹ {item?.price}
                  </p> */}
                  <div className="py-2 flex flex-col space-y-4">
                    <p className="font-inter text-primary-neutral-800 text-xs lg:text-sm font-light leading-5">
                      {item?.subtext}
                    </p>
                    {item?.points?.map((a) => {
                      return (
                        <div className="flex flex-row space-x-4">
                          <div className="w-4 h-4">
                            <svg
                              className="mt-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M5.86615 10.6018L3.06615 7.80182L2.13281 8.73516L5.86615 12.4685L13.8661 4.46849L12.9328 3.53516L5.86615 10.6018Z"
                                fill="#363430"
                              />
                            </svg>
                          </div>
                          <div className="flex flex-col space-y-2">
                            <h1 className="font-inter text-sm lg:text-lg font-semibold leading-6 text-primary-neutral-800">
                              {a?.text}
                            </h1>
                            <p className="font-inter text-primary-neutral-800 text-xs lg:text-sm font-light leading-5">
                              {a?.subtext}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <div className="w-full flex flex-col items-center py-4 mx-auto max-w-5xl">
          <h1 className="font-light text-center text-lg lg:text-[32px]">
            Get started with your venture acceleration journey with Caarya today
          </h1>
          <p className="text-sm lg:text-xl font-light font-inter text-center text-gray-800 mt-4">
            Schedule a call with our startup solutions expert for a free audit
          </p>{" "}
          <button
            className="mt-6 rounded-xl bg-gradient-to-b from-red-500 to-yellow-500 text-white px-8 text-sm py-4 relative shadow-lg mx-auto"
            onClick={() => navigate("/get-in-touch")}
          >
            Book Now
          </button>
        </div> */}
        <div className="flex flex-row items-center justify-center">
          <img src="/assets/caaryaLogos/logo.svg" alt="" className="h-20" />
        </div>
      </div>
    </section>
  );
}

export default Pricing;
