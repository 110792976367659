import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Navigation from "components/Common/Navigation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateDetails } from "redux/getInTouch";

const styles = {
  "& .MuiInputLabel-shrink.Mui-focused": {
    color: "#282724",
  },
  "& .MuiOutlinedInput-root": {
    outline: "none",
    borderRadius: "4px",
    "& .MuiOutlinedInput-input": {
      boxShadow: "none",
    },
    "& fieldset": {
      borderColor: "#CFCDC9",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#282724",
    },
  },
};

const GetInTouch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const details = useSelector((state) => state?.getInTouch?.startup);
  const [error, setError] = useState({});
  const [state, setState] = useState({
    name: "",
    ownerName: "",
    email: "",
    phoneNumber: "",
  });

  useEffect(() => {
    setState({
      name: details?.name,
      ownerName: "",
      email: details?.email || "",
      phoneNumber: details?.phoneNumber || "",
    });
  }, []);

  const handleSubmit = () => {
    let error = false;
    let e = {};
    if (state?.name == "") {
      e = { ...e, name: true };
      error = true;
    }
    if (state?.ownerName == "") {
      e = { ...e, ownerName: true };
      error = true;
    }
    if (state?.email == "") {
      e = { ...e, email: true };
      error = true;
    }
    if (state?.phoneNumber == "") {
      e = { ...e, phoneNumber: true };
      error = true;
    }
    if (!state?.email?.includes("@") || !state?.email?.includes(".")) {
      e = { ...e, email: true };
      error = true;
    }
    if (error) {
      setError(e);
      return;
    } else {
      dispatch(updateDetails({ details: state }));
      navigate("/options");
    }
  };

  return (
    <section className="min-h-screen flex flex-col items-center pb-20">
      <div className="flex pt-10 px-4 items-center flex-col gap-2 text-center my-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="64"
          height="64"
          viewBox="0 0 64 64"
          fill="none"
        >
          <g clip-path="url(#clip0_431_7287)">
            <path
              d="M43.0215 0C31.454 0 22.0431 9.4109 22.0431 20.9784C22.0431 26.4257 24.1306 31.394 27.5468 35.1273L23.6744 38.9996L23.6288 38.954C23.0733 38.3985 22.3345 38.0924 21.5488 38.0924C20.763 38.0924 20.0243 38.3984 19.4687 38.954L1.15316 57.2697C-0.384386 58.8074 -0.384386 61.3092 1.15316 62.8468C1.92193 63.6156 2.93184 64.0001 3.94174 64.0001C4.95165 64.0001 5.96155 63.6157 6.73032 62.8468L25.0459 44.5313C25.6015 43.9757 25.9075 43.237 25.9075 42.4512C25.9075 41.6655 25.6015 40.9268 25.0459 40.3712L25.0002 40.3256L28.8726 36.4532C32.6058 39.8694 37.5743 41.9569 43.0215 41.9569C54.5891 41.9569 64 32.546 64 20.9784C64 9.4109 54.5891 0 43.0215 0ZM5.40453 61.5209C4.59801 62.3275 3.2856 62.3275 2.47907 61.5209C1.67255 60.7144 1.67255 59.402 2.47907 58.5955L17.8636 43.2109L20.7891 46.1364L5.40453 61.5209ZM23.7201 43.2054L22.1149 44.8106L19.1894 41.8851L20.7946 40.2799C20.996 40.0786 21.2639 39.9675 21.5488 39.9675C21.8336 39.9675 22.1015 40.0786 22.3029 40.2799L23.7199 41.6971C23.9215 41.8986 24.0323 42.1664 24.0323 42.4512C24.0325 42.7361 23.9216 43.0039 23.7201 43.2054ZM43.0215 40.0819C32.4878 40.0819 23.9182 31.5122 23.9182 20.9785C23.9182 10.4448 32.4879 1.87505 43.0215 1.87505C53.5551 1.87505 62.1249 10.4448 62.1249 20.9784C62.1249 31.512 53.5552 40.0819 43.0215 40.0819Z"
              fill="#CFCDC9"
            />
            <path
              d="M55.0209 8.97778C48.9868 2.94372 39.3579 2.34358 32.6237 7.58148C32.215 7.89937 32.1413 8.48839 32.4593 8.89702C32.7771 9.30578 33.3661 9.37941 33.7749 9.0614C39.7646 4.40264 48.3283 4.93678 53.6951 10.3036C59.0619 15.6705 59.5959 24.2342 54.9373 30.2236C54.6194 30.6324 54.693 31.2214 55.1016 31.5393C55.2728 31.6723 55.4754 31.7368 55.6765 31.7368C55.9559 31.7368 56.2324 31.6124 56.4172 31.3748C61.6552 24.6406 61.0551 15.012 55.0209 8.97778Z"
              fill="#CFCDC9"
            />
            <path
              d="M52.2687 32.8941C49.5386 35.0176 46.2743 36.0623 43.0211 36.0622C39.1371 36.062 35.2691 34.5726 32.3486 31.652C26.9818 26.2852 26.4478 17.7214 31.1065 11.7319C31.4244 11.3232 31.3507 10.7342 30.9421 10.4163C30.5332 10.0984 29.9443 10.172 29.6265 10.5807C24.3884 17.3151 24.9887 26.9438 31.0228 32.9778C34.3068 36.2619 38.6547 37.9363 43.0222 37.9362C46.6794 37.9361 50.3508 36.7614 53.42 34.3741C53.8288 34.0562 53.9023 33.4672 53.5844 33.0584C53.2664 32.6499 52.6772 32.5762 52.2687 32.8941Z"
              fill="#CFCDC9"
            />
            <path
              d="M35.3449 20.6398C35.5279 20.8229 35.7679 20.9144 36.0078 20.9144C36.2477 20.9144 36.4877 20.8229 36.6707 20.6398L38.0119 19.2986L39.353 20.6398C39.536 20.8229 39.776 20.9144 40.0159 20.9144C40.2558 20.9144 40.4958 20.8229 40.6788 20.6398C41.045 20.2736 41.045 19.6801 40.6788 19.314L39.3377 17.9728L40.6788 16.6316C41.045 16.2655 41.045 15.672 40.6788 15.3059C40.3127 14.9397 39.719 14.9397 39.353 15.3059L38.0119 16.647L36.6707 15.3059C36.3046 14.9397 35.7109 14.9397 35.3449 15.3059C34.9788 15.672 34.9788 16.2656 35.3449 16.6316L36.6861 17.9728L35.3449 19.314C34.9788 19.6801 34.9788 20.2737 35.3449 20.6398Z"
              fill="#CFCDC9"
            />
            <path
              d="M45.3645 20.6398C45.5475 20.8229 45.7876 20.9144 46.0274 20.9144C46.2673 20.9144 46.5073 20.8229 46.6903 20.6398L48.0315 19.2986L49.3727 20.6398C49.5557 20.8229 49.7957 20.9144 50.0356 20.9144C50.2754 20.9144 50.5154 20.8229 50.6984 20.6398C51.0646 20.2736 51.0646 19.68 50.6984 19.314L49.3573 17.9728L50.6984 16.6316C51.0646 16.2655 51.0646 15.672 50.6984 15.3059C50.3323 14.9397 49.7387 14.9397 49.3727 15.3059L48.0315 16.647L46.6903 15.3059C46.3242 14.9397 45.7306 14.9397 45.3645 15.3059C44.9984 15.672 44.9984 16.2656 45.3645 16.6316L46.7057 17.9728L45.3645 19.314C44.9983 19.6801 44.9983 20.2737 45.3645 20.6398Z"
              fill="#CFCDC9"
            />
            <path
              d="M37.3482 27.3298C36.9825 27.6964 36.983 28.29 37.3493 28.6558C37.5323 28.8384 37.772 28.9298 38.0117 28.9298C38.2519 28.9298 38.4921 28.838 38.6753 28.6546C39.772 27.5559 41.3563 26.9257 43.0219 26.9257C44.6874 26.9257 46.2718 27.5559 47.3686 28.6546C47.7344 29.0211 48.328 29.0216 48.6944 28.6558C49.0608 28.29 49.0614 27.6964 48.6955 27.33C47.2496 25.8814 45.1816 25.0508 43.0218 25.0508C40.8622 25.0505 38.7941 25.8813 37.3482 27.3298Z"
              fill="#CFCDC9"
            />
          </g>
          <defs>
            <clipPath id="clip0_431_7287">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>{" "}
        <h2 className="font-satoshi text-2xl sm:text-[28px] font-medium tracking-[2.5px]">
          Looks like you’re not in our database!
        </h2>
        <p className="text-black font-satoshi text-xl sm:text-2xl font-light tracking-[1.5px]">
          Please fill in your details to get started
        </p>
      </div>
      <Box className="my-12 max-w-lg w-full flex flex-col gap-6 px-4">
        <TextField
          label="Startup Name"
          variant="outlined"
          placeholder="Startup Name Filled Here"
          fullWidth
          error={error?.name}
          sx={styles}
          value={state.name}
          onChange={(e) => setState({ ...state, name: e.target.value })}
        />
        <TextField
          autoFocus
          label="Your Name"
          variant="outlined"
          fullWidth
          error={error?.ownerName}
          sx={styles}
          value={state.ownerName}
          onChange={(e) => setState({ ...state, ownerName: e.target.value })}
        />
        <TextField
          label="Preferred E-mail"
          variant="outlined"
          fullWidth
          sx={styles}
          type="email"
          error={error?.email}
          value={state.email}
          onChange={(e) => {
            setState({ ...state, email: e.target.value });
          }}
        />
        <TextField
          label="Preferred Contact Number"
          variant="outlined"
          fullWidth
          sx={styles}
          error={error?.phoneNumber}
          value={state.phoneNumber}
          onChange={(e) => {
            e.target.value = e.target.value.trimStart().replace(/[^0-9]/gi, "");
            if (e.target.value?.length <= 10)
              setState({ ...state, phoneNumber: e.target.value });
          }}
        />
      </Box>
      <button
        className="rounded-lg bg-[#1a1a1a] flex flex-row items-center space-x-2 text-white pl-8 pr-5 text-base font-lato font-bold py-4 relative mx-auto"
        onClick={handleSubmit}
      >
        <p>Continue</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            d="M12.5 4L11.09 5.41L16.67 11H4.5V13H16.67L11.09 18.59L12.5 20L20.5 12L12.5 4Z"
            fill="#FAFAFA"
          />
        </svg>
      </button>
    </section>
  );
};

export default GetInTouch;
