import { concerns } from "helpers/constants/startupForm";
import { useDispatch, useSelector } from "react-redux";
import { updateDetails } from "redux/startupForm";

const ConcernForm = ({ field }) => {
  const { details } = useSelector((state) => state.startupForm);
  const dispatch = useDispatch();

  const handleOptionClick = (option) => {
    if (details[field].includes(option)) {
      const value = details[field].filter((_option) => _option !== option);
      dispatch(updateDetails({ field, value }));
    } else {
      dispatch(updateDetails({ field, value: [...details[field], option] }));
    }
  };

  return (
    <div className="md:px-20 w-full">
      <div className="p-4 md:p-6 md:px-10 flex flex-col gap-8 ">
        <div className="flex flex-col gap-2 text-center">
          <h1 className="text-lg md:text-xl font-poppins tracking-[2%]">
            Which of these might be of concern for you when availing our services?
          </h1>
          <p className="font-inter text-sm md:text-base tracking-[2%] font-light">Select the ones relevant to you</p>
        </div>
        <div className="w-full flex flex-wrap items-center justify-center gap-4 md:gap-6 py-4 pb-20 ">
          {concerns.map((challenge, idx) => {
            const isSelected = details[field].includes(challenge);
            return (
              <div
                key={idx}
                onClick={() => handleOptionClick(challenge)}
                className={`rounded-full w-full sm:max-w-max p-6 py-[10px] border border-primary-grey-200 cursor-pointer hover:bg-primary-grey-150 ${
                  isSelected && "bg-primary-grey-150"
                } `}>
                <p className="font-inter text-sm tracking-[2%] text-primary-grey-800 text-center">{challenge}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ConcernForm;
