import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateStep } from "redux/startupForm";
import { BiRightArrowAlt, BiLeftArrowAlt } from "react-icons/bi";
import { FormComponents } from "helpers/constants/startupForm";

const FormNavigation = ({ field }) => {
  const dispatch = useDispatch();
  const { step } = useSelector((state) => state.startupForm);

  const getLeftDisabled = (step) => {
    if (step === 0) return true;
    return false;
  };
  const getRightDisabled = (step) => {
    if (step === FormComponents.length - 1) return true;
    return false;
  };

  return (
    <div className="bg-primary-grey-100  z-10 fixed bottom-0 inset-x-0 grid place-items-center p-2">
      <div className="flex p-1 justify-center items-center gap-14">
        <button
          disabled={getLeftDisabled(step)}
          onClick={() => dispatch(updateStep(step - 1))}
          className=" grid place-items-center w-12 h-12 rounded-full border border-primary-grey-500 text-primary-grey-500 disabled:text-primary-grey-300 disabled:border-primary-grey-300 ">
          <BiLeftArrowAlt className="text-xl" />
        </button>
        <h3 className="font-inter font-light text-md md:text-base">{FormComponents.length - step} Steps to Go</h3>
        <button
          disabled={getRightDisabled(step)}
          onClick={() => dispatch(updateStep(step + 1))}
          className=" grid place-items-center w-12 h-12 rounded-full border border-primary-grey-500 text-primary-grey-500  disabled:text-primary-grey-300 disabled:border-primary-grey-300  ">
          <BiRightArrowAlt className="text-xl" />
        </button>
      </div>
    </div>
  );
};

export default FormNavigation;
