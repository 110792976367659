import { useEffect, useRef, useState } from "react";
import { StepOne, StepTwo, StepThree } from "./StepsContent";
import { notifySuccess, notifyError } from "helpers/toast";
import {
  ERROR,
  INVALID_EMAIL,
  INVALID_OTP,
  INVALID_STARTUP,
  SUCCESS,
} from "helpers/constants";
import { sendOTP, verifyOTP } from "helpers/utils/startup";
import { useNavigate } from "react-router-dom";
import logo from "assets/images/icons/logo.svg";
import { useDispatch } from "react-redux";
import { addStartup } from "redux/getInTouch";

export default function Box({ startups = [] }) {
  const dispatch = useDispatch();

  const [step, setStep] = useState(0);
  const [startupNames, setStartupNames] = useState(startups);
  const [selectedStartup, setSelectedStartup] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [timer, setTimer] = useState(-1);

  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  const navigate = useNavigate();

  const handleInput = (e) => {
    const input = e.target.value.toLowerCase();
    const filteredStartupNames =
      input.length > 0
        ? startups.filter((startup) =>
            startup.name?.toLowerCase().includes(input)
          )
        : startups;

    setStartupNames(filteredStartupNames);
    setShowDropdown(input.length > 0);
    setInputValue(e.target.value);
  };

  // Close dropdown when clicked outside - apart from dropdown items
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  // handles the click action of Continue button
  // validates data at each step, and if valid,
  // moves to next step (also performs other actions like sending OTP, verifying OTP, etc.)
  const handleNextStep = async () => {
    let s = selectedStartup
      ? selectedStartup
      : startups.find(
          (startup) => startup.name?.toLowerCase() == inputValue?.toLowerCase()
        )
      ? startups.find(
          (startup) => startup.name?.toLowerCase() == inputValue?.toLowerCase()
        )
      : null;

    dispatch(addStartup({ startup: s || { name: inputValue } }));
    if (s) navigate("/options");
    else navigate("/get-in-touch");
    return;

    // const {
    //   valid,
    //   message,
    //   type,
    //   navigateTo = "",
    // } = await validateStepData(step);
    // if (type == "New") {
    //   navigate("/options");
    // }
    // if (valid && step < 2) {
    //   dispatch(addStartup({ startup: selectedStartup }));
    //   navigate("/options"); //   setStep(step + 1);
    // }
    // if (message) {
    //   if (type === ERROR) {
    //     notifyError(message);
    //   } else if (type === SUCCESS) {
    //     notifySuccess(message);
    //   }
    // }
    // if (navigateTo) {
    //   navigate("/options"); // navigate(navigateTo);
    // }
  };

  // validates data at each step
  const validateStepData = async (step) => {
    const validateStartupStep = () => {
      if (isValidStartup(inputValue)) {
        setEmail(selectedStartup?.email || "");
      }
      return inputValue.length && isValidStartup(inputValue)
        ? { valid: true }
        : inputValue.length
        ? { valid: true, type: "New" }
        : { valid: false, message: INVALID_STARTUP, type: ERROR };
    };

    const validateEmailStep = async () => {
      const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
      const isValid = emailRegex.test(email?.trim());
      if (isValid && selectedStartup) {
        const { data, error } = await sendOTP(selectedStartup.id, email);
        return error
          ? { valid: false, message: error.message, type: ERROR }
          : { valid: true, type: SUCCESS, message: data.message };
      } else {
        return { valid: false, message: INVALID_EMAIL, type: ERROR };
      }
    };

    const validateOTPStep = async () => {
      if (otp.length !== 6) {
        return { valid: false, message: INVALID_OTP, type: ERROR };
      }
      const { data, error } = await verifyOTP(selectedStartup.id, email, otp);
      return error
        ? { valid: false, message: error.message, type: ERROR }
        : {
            valid: true,
            message: data.message,
            type: SUCCESS,
            navigateTo: `/startups/${selectedStartup.id}`,
          };
    };

    switch (step) {
      case 0:
        return validateStartupStep();
      case 1:
        return validateEmailStep();
      case 2:
        return validateOTPStep();
      default:
        return false;
    }
  };

  const isValidStartup = (name) => {
    return startups.some((s) => s.name === name);
  };

  // resets the state to initial values
  const tryDifferentEmail = () => {
    setStep(1);
    setEmail("");
    setOtp("");
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (step === 2) {
      setTimer(10);
      const interval = setInterval(() => {
        setTimer((timer) => (timer > 0 ? timer - 1 : 0));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [step]);

  const renderData = (step, cb) => {
    switch (step) {
      case 0:
        return (
          <StepOne
            {...{
              inputValue,
              handleInput,
              inputRef,
              startupNames,
              setSelectedStartup,
              setShowDropdown,
              showDropdown,
              dropdownRef,
              setInputValue,
              cb,
            }}
          />
        );
      case 1:
        return (
          <StepTwo
            {...{
              selectedStartup,
              email,
              setEmail,
              setShowDropdown,
            }}
          />
        );
      case 2:
        return (
          <StepThree
            {...{
              selectedStartup,
              email,
              otp,
              setOtp,
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex max-w-[600px] w-full py-8 flex-col items-center justify-start h-full mt-[20vh] lg:mt-0 lg:justify-center gap-10 rounded-lg">
      <div className="flex flex-col justify-center items-center gap-4">
        <div className="w-[80px] h-[80px]">
          <img src={logo} alt="logo" />
        </div>
        <h1
          className="font-poppins logo-text text-center lg:text-[40px] text-[24px] leading-9 tracking-[6px] uppercase"
          id="WelcomeText"
        >
          Founder Nexus
        </h1>
      </div>
      {renderData(step, handleNextStep)}
      <div className="flex flex-col gap-4">
        {step !== 0 && (
          <button
            className="rounded-xl bg-gradient-to-b from-red-500 to-yellow-500 text-white px-8 text-sm py-4 relative shadow-lg mx-auto"
            onClick={handleNextStep}
          >
            Continue
          </button>
        )}
        {step === 2 && (
          <div className="flex flex-col gap-4 text-sm text-center font-inter leading-7 text-white">
            {timer > 0 ? (
              <p className="font-semibold">
                Resend OTP in 00:{timer < 10 ? `0${timer}` : timer}
              </p>
            ) : (
              <p
                className="font-semibold underline cursor-pointer"
                onClick={sendOTP}
              >
                Resend OTP
              </p>
            )}
            <p className="">OR</p>
            <p
              className="font-semibold underline cursor-pointer"
              onClick={tryDifferentEmail}
            >
              Try A Different Email ID
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
