import React from "react";

function Navigation() {
  return (
    <div className="py-5 px-4 flex flex-row w-full justify-center items-center space-x-4 border-b border-primary-neutral-200">
      <img src="/assets/logos/logo.png" alt="" className="" />
      <p className="text-gradient font-poppins text-lg font-medium tracking-[0.9px]">
        Founder Nexus
      </p>
    </div>
  );
}

export default Navigation;
