import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { BiCheckCircle } from "react-icons/bi";
import { PiPlusCircle } from "react-icons/pi";

const Item = ({
  title,
  svg,
  information,
  setInformation,
  description,
  subtitle,
  heading,
}) => {
  return (
    <div
      onClick={(e) => {
        let temp = { ...information };
        if (temp[heading]) {
          let items = [...temp[heading]];

          if (items?.includes(title)) {
            items = items?.filter((i) => i !== title);
          } else {
            items.push(title);
          }
          temp[heading] = items;
        } else {
          temp[heading] = [title];
        }
        setInformation(temp);
      }}
      style={{
        boxShadow:
          "0px 4px 6px -4px rgba(0, 0, 0, 0.10), 0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 0px 16px 0px rgba(0, 0, 0, 0.02)",
      }}
      className="hover:border hover:border-primary-neutral-200 cursor-pointer bg-white lg:m-4 p-5 rounded-lg flex flex-col space-y-6 md:max-w-[320px]"
    >
      <div className="flex flex-row items-center justify-between">
        <div>{React.cloneElement(svg, {})}</div>

        <div>
          {information &&
          information[heading] &&
          information[heading]?.find((i) => i == title) ? (
            <BiCheckCircle className="w-4 h-4 text-[#363430]" />
          ) : (
            <PiPlusCircle className="w-4 h-4 text-[#E7E6E5]" />
          )}
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <h1 className="text-primary-neutral-800 font-lato text-base font-semibold leading-6">
          {title}
        </h1>
        <p className="text-primary-neutral-500 font-lato text-xs leading-[18px]">
          {description}
        </p>
      </div>
      <div className="flex flex-row items-center justify-end text-primary-neutral-300 font-lato text-2xs font-bold">
        {subtitle}
      </div>
    </div>
  );
};

export default function Service({
  title,
  icon,
  svg,
  items = [],
  information,
  setInformation,
  data,
}) {
  return (
    <div className="flex flex-col w-full gap-8 py-10">
      <div className="flex flex-col items-center space-y-2 justify-center">
        {svg}
        <h3 className="text-lg text-center font-bold font-satoshi text-primary-neutral-800">
          {title}
        </h3>
      </div>

      <div className="flex flex-row flex-wrap justify-center gap-8 lg:gap-0">
        {/* {items.map((item, idx) => (
          <Item
            key={idx}
            {...item}
            heading={title}
            information={information}
            setInformation={setInformation}
          />
        ))} */}
        {items.map((item, idx) => (
          <div>
            <AnimatedCard
              key={idx}
              {...item}
              heading={title}
              information={information}
              setInformation={setInformation}
              data={data}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

function AnimatedCard({
  title,
  svg,
  information,
  setInformation,
  description,
  subtitle,
  data,
  heading,
}) {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setShow(true);
      }, 500);
    }
  }, [open]);

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        setOpen(false);
      }, 50);
    }
  }, [show]);

  return (
    <button
      style={{
        boxShadow:
          "0px 4px 6px -4px rgba(0, 0, 0, 0.10), 0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 0px 16px 0px rgba(0, 0, 0, 0.02)",
      }}
      className={`${
        open
          ? "service-box border-transparent"
          : "service-box border-transparent"
      } hover:border hover:border-primary-neutral-200 min-h-[215px] cursor-pointer border bg-white w-full lg:m-4 p-5 rounded-lg md:max-w-[320px] max-h-max`}
      onClick={() => {
        let temp = { ...information };
        if (temp[heading]) {
          let items = [...temp[heading]];

          if (items?.includes(title)) {
            items = items?.filter((i) => i !== title);
          } else {
            items.push(title);
          }
          temp[heading] = items;
        } else {
          temp[heading] = [title];
        }
        setInformation(temp);
        open ? setShow((prev) => !prev) : setOpen((prev) => !prev);
      }}
    >
      <div className={`flex flex-col space-y-6 h-full w-full`}>
        <div className="flex flex-row items-center justify-between w-full">
          <div>{React.cloneElement(svg, {})}</div>
          <AnimatePresence initial={false} mode="wait">
            <motion.div
              key={open ? "minus" : "plus"}
              initial={{
                rotate: open ? -90 : 90,
              }}
              animate={{
                zIndex: 1,
                rotate: 0,
                transition: {
                  type: "tween",
                  duration: 0.1,
                  ease: "circOut",
                },
              }}
              exit={{
                zIndex: 0,
                rotate: open ? -90 : 90,
                transition: {
                  type: "tween",
                  duration: 0.1,
                  ease: "circIn",
                },
              }}
            >
              <div>
                {open ? (
                  <BiCheckCircle className="w-4 h-4 text-[#363430]" />
                ) : (
                  <PiPlusCircle className="w-4 h-4 text-[#E7E6E5]" />
                )}
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
        <div className="flex flex-col space-y-2 items-start">
          <h1 className="text-primary-neutral-800 font-lato text-base text-left font-semibold leading-6">
            {title}
          </h1>
          <p className="text-primary-neutral-500 font-lato text-xs leading-[18px] text-left">
            {description}
          </p>
        </div>

        <div className={`${open ? "show-issues" : "hide-issues"}`}>
          {/* {show && (
            <div className="w-full flex flex-col space-y-4 border-t border-primary-neutral-100 pt-4">
              <div className="px-3 text-primary-neutral-800 font-lato text-2xs font-light leading-6 tracking-[0.4px]">
                <span className="text-primary-red-medium font-lato font-bold text-base leading-6 tracking-[0.64px]">
                  {data?.find(
                    (i) =>
                      title
                        ?.toLowerCase()
                        ?.includes(i?.role_name?.toLowerCase()) ||
                      i?.role_name
                        ?.toLowerCase()
                        ?.includes(title?.toLowerCase())
                  )?.userCount || 0}
                </span>{" "}
                profiles to choose from
              </div>

              <div className="px-3 flex flex-col items-start space-y-1 text-left">
                <h1 className="text-primary-neutral-500 font-lato text-2xs font-bold leading-4 tracking-[0.5px]">
                  What You Get
                </h1>
                <p className="text-[#1A6BE5] font-lato text-xs font-normal leading-5 tracking-[0.48px]">
                  Brand Story, Brand Messaging
                </p>
              </div>
              <div className="w-full flex flex-row items-center justify-end text-primary-neutral-300 font-lato text-2xs font-bold">
                {subtitle}
              </div>
            </div>
          )} */}
        </div>
        <div
          className={`w-full flex flex-row items-center justify-end text-primary-neutral-300 font-lato text-2xs font-bold ${
            !open ? "" : ""
          }`}
        >
          {subtitle}
        </div>
      </div>
    </button>
  );
}
