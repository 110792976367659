import {
  MdOutlineRadioButtonChecked,
  MdOutlineRadioButtonUnchecked,
} from "react-icons/md";

import React, { useState } from "react";
import { currentStateOptions } from "helpers/constants/startupForm";
import { useNavigate } from "react-router-dom";
import Navigation from "components/Common/Navigation";
import { useDispatch, useSelector } from "react-redux";
import { updateInformation } from "redux/getInTouch";
import { BiCheckCircle } from "react-icons/bi";

const Options = () => {
  const details = useSelector((state) => state?.getInTouch);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  return (
    <div className="relative">
      {/* <Navigation /> */}

      <div className="min-h-screen flex flex-col justify-center overflow-y-scroll scrollbar-hidden w-full py-12">
        <div className="p-4 md:p-6 md:px-10 flex flex-col gap-12 max-w-7xl mx-auto">
          <div className="flex flex-col gap-6 text-center">
            <p className="font-satoshi text-sm text-primary-neutral-500 tracking-[0.35px]">
              Hello team {details?.details?.name || details?.startup?.name}! You
              currently have{" "}
              <u>{details?.startup?.metaData?.info?.length || 0}</u> ongoing
              requests with us{" "}
            </p>{" "}
            <div className="flex flex-col items-center space-y-2 text-2xl md:text-[32px] font-light font-satoshi tracking-[2%]">
              <p className="text-black tracking-[2.24px] leading-[42px] text-2xl lg:text-[28px] font-medium text-center">
                Let’s understand your requirements
              </p>

              <p className="text-black font-light text-xl lg:text-2xl leading-9 tracking-[0.6px]">
                What are your current needs?
              </p>
            </div>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-10 py-10">
            {currentStateOptions.map((option, idx) => {
              const { title, subtitle, icon, image } = option;
              const isSelected = selected?.includes(title);
              return (
                <div
                  key={idx}
                  onClick={() => {
                    let s = [...selected];

                    if (isSelected) s = s?.filter((i) => i !== title);
                    else s.push(title);

                    setSelected(s);
                  }}
                  style={{
                    boxShadow:
                      "0px 4px 6px -4px rgba(0, 0, 0, 0.10), 0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 0px 16px 0px rgba(0, 0, 0, 0.02)",
                  }}
                  className={`p-12 cursor-pointer rounded-2xl flex flex-col relative ${
                    isSelected ? "border border-[#1a1a1a]" : "bg-white"
                  } `}
                >
                  <h1 className="text-primary-neutral-800 z-20 font-satoshi text-[32px] font-medium leading-[48px] tracking-[1.6px]">
                    {title}
                  </h1>
                  <p className="mt-4 text-primary-neutral-800 z-20 font-lato text-sm font-light leading-[21px] tracking-[0.7px]">
                    {subtitle}
                  </p>
                  {isSelected && (
                    <BiCheckCircle className="text-[#1a1a1a] w-4 h-4 absolute top-2 left-2" />
                  )}
                  <img
                    src={image}
                    alt=""
                    className="absolute z-0 top-0 right-0 rounded-tr-2xl"
                  />
                </div>
              );
            })}
          </div>
          <button
            className="rounded-lg bg-[#1a1a1a] flex flex-row items-center space-x-2 text-white pl-8 pr-5 text-base font-lato font-bold py-4 relative mx-auto"
            onClick={() => {
              dispatch(
                updateInformation({ key: "currentStage", value: selected })
              );
              navigate("/services");
            }}
          >
            <p>Continue</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M12.5 4L11.09 5.41L16.67 11H4.5V13H16.67L11.09 18.59L12.5 20L20.5 12L12.5 4Z"
                fill="#FAFAFA"
              />
            </svg>
          </button>{" "}
        </div>
      </div>
    </div>
  );
};

export default Options;
